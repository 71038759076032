:root {
    --primary-color: #c4c4c4;
    --secondary-color: #00332a;
    --text-color: #9695956f;
    --highlight-color: #ffa726;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--primary-color);
    color: var(--text-color);
}

.home-container {
    padding: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.styled-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: #36a901;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.styled-button:hover {
    background-color: #7fa96b;
}

/* Hero Section */
.hero-section {
    text-align: center;
    padding: 100px 20px;
    background-color: var(--secondary-color);
}

.hero-title {
    font-size: 3rem;
    color: var(--highlight-color);
    margin: 0;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 10px 0 0 0;
}

.featured-photos img {
    height: auto;
    margin: 0 auto;
    display: block;
}

.featured-photos img {
    height: auto;
    margin: 0 auto;
    display: block;
}

.featured-photos {
    margin: 20px 0;
    /* Adjust as necessary */
    padding: 10px;
    /* Add padding to improve spacing */
    text-align: center;
}

.featured-photos h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-style: italic;
    color: var(--secondary-color);
}

.featured-photos h5 {
    font-size: 1rem;
    margin-bottom: 10px;
    font-style: italic;
    color: var(--secondary-color);
}

.featured-video {
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 20px;
    color: var(--secondary-color);
    text-align: center;
}

/* About Us Section */
.about-us {
    margin: 40px 0;
    text-align: left;
    margin-left: 25%;
    margin-right: 25%;
}

.about-us h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--secondary-color);
}

.about-us-content {
    display: flex;
    flex-direction: column;
    /* Stacks elements vertically on mobile */
    align-items: flex-start;
}

.about-us-logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    /* Space between the logo and text for mobile */
}

.about-us-right-image {
    margin-left: 30px;
    /* Space between the text and right image */
}

.about-us p {
    margin: 0;
    color: var(--secondary-color);
    line-height: 1.6;
}

/* Adjust layout for larger screens */
@media (min-width: 768px) {
    .about-us-content {
        flex-direction: row;
        /* Row layout for larger screens */
        align-items: center;
    }

    .about-us-logo {
        margin-bottom: 0;
        margin-right: 20px;
    }

    .about-us-right-image {
        margin-left: 20px;
    }
}