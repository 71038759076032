/* Take A Hike Page Styles */
.take-a-hike-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #444444;
    /* Set the default text color for the whole page */
}

.take-a-hike-page h1 {
    text-align: center;
    font-size: 2rem;
    color: #444;
}

/* Filters Section */
.take-a-hike-page .filters {
    display: flex;
    justify-content: space-between;
    /* Ensures the filters are spaced evenly */
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
}

.take-a-hike-page .filter {
    flex: 1;
    min-width: 200px;
    /* Adjusted to make sure it’s aligned properly */
    margin: 10px;
}

.take-a-hike-page .filters select,
.take-a-hike-page .filter input {
    padding: 8px;
    font-size: 1rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.take-a-hike-page .filters select:focus,
.take-a-hike-page .filter input:focus {
    border-color: #0056b3;
    outline: none;
}

.take-a-hike-page .filter label {
    font-weight: bold;
    color: #444;
    margin-bottom: 8px;
    /* Adds space between the label and input */
    display: block;
    /* Ensures the label is on top of the input */
}

/* Photo Grid */
.take-a-hike-page .photo-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.take-a-hike-page .photo-container {
    width: 300px;
    cursor: pointer;
    text-align: center;
}

.take-a-hike-page .hike-photo {
    width: 100%;
    border-radius: 5px;
}

.take-a-hike-page .hike-photo:hover {
    transform: scale(1.1); /* Increases the size to 1.1 times */
}

.take-a-hike-page .photo-title {
    margin-top: 10px;
    font-weight: bold;
}

/* Hike Details */
.take-a-hike-page .hike-details {
    margin-top: 40px;
}

.take-a-hike-page .hike-photos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.take-a-hike-page .hike-photos img {
    margin-top: 10px;
    max-height: 400px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 8px;
    border: 3px solid #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.take-a-hike-page h2 {
    text-align: center;
}

.take-a-hike-page iframe {
    width: 560px;
    /* Set the width explicitly */
    height: 315px;
    /* Set the height explicitly */
    margin-top: 50px;
    max-width: 100%;
    /* Ensure it doesn't exceed the container's width on small screens */
    display: block;
    /* Ensures centering works */
    margin: 0 auto;
    /* Optional: Center the iframe horizontally */
}

/* Styles for the photo grid */
.take-a-hike-page .photo-grid .photo-container {
    transition: transform 0.3s ease, filter 0.3s ease;
    /* Smooth transition for both transform and filter */
}

.take-a-hike-page .photo-grid .photo-container.selected .hike-photo {
    transform: scale(1.0);
    /* Expand the image by 10% */
}

.take-a-hike-page .photo-grid .photo-container.grayscale .hike-photo {
    filter: grayscale(100%);
    /* Apply grayscale to unselected images */
}

.take-a-hike-page .photo-grid .photo-container.selected .hike-photo {
    filter: none;
    /* Remove grayscale for the selected image */
}

.take-a-hike-page .hike-details-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 25px;
    max-width: 85%;
    margin: 40px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.take-a-hike-page .hike-details-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.take-a-hike-page .hike-details-card h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.take-a-hike-page .hike-details-card .hike-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    font-size: 1rem;
    color: #555;
}

.take-a-hike-page .hike-details-card .hike-info p {
    margin: 0;
}

.take-a-hike-page .hike-details-card .hike-info p strong {
    font-weight: bold;
    color: #333;
}

.take-a-hike-page .hike-details-card .hike-info p:nth-child(odd) {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
}

.take-a-hike-page .hike-details-card .hike-info p:nth-child(even) {
    padding: 10px;
    border-radius: 5px;
}

@media (max-width: 600px) {
    .take-a-hike-page .hike-details-card .hike-info {
        grid-template-columns: 1fr;
    }
}

.take-a-hike-page .highlight {
    text-align: center;
    font-style: italic;
}

.take-a-hike-page .hike-photo-detail {
    max-width: 90%;
}

.take-a-hike-page map-section {
    display: flex;
    justify-content: center;
    margin: 40px;
}