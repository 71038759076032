/* src/Header.css */

/* General Header Styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--secondary-color);
    /* Darker green background */
    color: var(--text-color);
    position: relative;
    z-index: 100;
}

.logo h1 {
    font-size: 2rem;
    margin: 0;
    color: var(--text-color);
}

.header-img {
    width: 75px;
    height: auto;
    border-radius: 50%;
}

.logo a {
    text-decoration: none;
    color: var(--text-color);
    /* Keep the logo link white */
}

.nav {
    display: flex;
    align-items: center;
}

.nav ul {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
}

.nav ul li a {
    font-family: 'Roboto', sans-serif;
    /* Simple sans-serif for navigation links */
    color: var(--text-color);
    padding: 10px;
    transition: color var(--transition-duration);
}

.nav ul li a:hover {
    color: var(--highlight-color);
    /* Orange highlight on hover */
}

/* Hamburger Menu for Mobile */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: var(--text-color);
    margin: 4px 0;
    transition: 0.3s;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .nav {
        position: absolute;
        top: 70px;
        right: 0;
        background-color: var(--secondary-color);
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }

    .nav.open {
        max-height: 300px;
        /* Adjust as needed */
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .hamburger {
        display: flex;
    }

    .logo h1 {
        font-size: 1.7rem;
        /* Slightly smaller font size for mobile */
    }

    .header-img {
        display: none;
    }
}