/* src/Footer.css */

.footer {
    background-color: #00332a;
    /* Dark background to contrast with the page */
    color: white;
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-content {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.footer-content a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.footer-content a:hover {
    color: #ffa726;
    /* Highlight color on hover */
}

.footer-text {
    font-size: 0.9rem;
    color: #c4c4c4;
}