/* Styling for the photo section */
.photo-section {
    column-count: 4;
    column-gap: 10px;
    padding: 5px;
}

/* Adjust the number of columns for different screen sizes */
@media (max-width: 1024px) {
    .photo-section {
        column-count: 2;
    }
}

@media (max-width: 768px) {
    .photo-section {
        column-count: 1;
    }
}

/* Photo container to hold image and subtitle */
.photo-container {
    position: relative;
    margin-bottom: 5px;
    display: inline-block;
    cursor: pointer;
}

/* Image styles */
.photo {
    width: 100%;
    border: 3px solid #f0f0f0;
    box-sizing: border-box;
    opacity: 1;
    transition: transform 1s, opacity 1s;
}

/* Animate image size when clicked */
.photo-container.active .photo {
    width: calc(100%);
}

.photo-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6); /* Increase opacity for better contrast */
    color: #2c3e50; /* Dark, muted color for text */
    text-align: center;
    padding: 8px;
    font-size: 0.9rem; /* Slightly smaller for a minimalist look */
    font-weight: 300; /* Lighter font weight for hipster aesthetic */
    letter-spacing: 1px; /* Add letter spacing for a modern touch */
    font-family: 'Courier New', Courier, monospace; /* Trendy, typewriter-style font */
    text-transform: uppercase; /* Uppercase for modern feel */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); /* Stronger shadow for readability on dark backgrounds */
    z-index: 1;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); /* Thin border at the bottom for a framed look */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow for lifted effect */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.photo-title:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Even more solid on hover for readability */
}


/* Subtitle at the bottom of the image */
.photo-subtitle {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    background-color: rgba(98, 98, 98, 0.6);
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-style: italic;
}

/* Show subtitle when image is clicked */
.photo-container.active .photo-subtitle {
    opacity: 1;
}

/* Animation for image opacity */
.loaded {
    opacity: 1;
}

.lessons-section {
    background-color: rgba(255, 255, 255, 0.8); /* Slight white background with transparency */
    padding: 20px;
    border-radius: 10px;
    line-height: 1.8; /* Increase line spacing */
    font-size: 1.1rem; /* Slightly increase font size */
    color: #333; /* Dark text for better readability */
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow for emphasis */
    display: flex;
    flex-direction: column; /* Make items stack on mobile */
    align-items: center;
}

/* Container for images and text */
.dog-images-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

/* Image styles */
.dog-image {
    width: 150px;
    height: 150px;
    border-radius: 50%; /* Circular images */
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0 20px;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
    .lessons-section {
        display: block;
        padding: 10px;
    }

    .dog-images-container {
        display: block; /* Stack images and text vertically */
        text-align: center;
        margin-bottom: 0;
    }

    .dog-image {
        width: 120px;
        height: 120px;
        margin: 10px auto;
    }

    p {
        margin: 20px 0;
        text-align: center;
        flex: none; /* Prevent text from flexing */
    }
}

.centered-intro {
    text-align: center; /* Center the first line */
    font-size: 1.2rem;  /* Slightly increase font size for emphasis */
    margin-bottom: 10px; /* Add some space below */
    margin-top: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #444;
}